import chartDark from '../assets/chart-dark.svg';
import chartIcon from '../assets/Chart.svg';
import { I18N } from '../i18n/i18n';
import { RouteNavbar } from '../Sidebar/RouteNavbar';
import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { useLanguageState } from '../States/LanguageState';
import { Link } from 'react-router-dom';
import videoDark from '../assets/video-dark.svg';
import videoIcon from '../assets/Video.svg';
import lockDark from '../assets/lock-dark.svg';
import lockIcon from '../assets/Lock.svg';
import messageDark from '../assets/message-dark.svg';
import messageIcon from '../assets/Message.svg';
import gameDark from '../assets/game-dark.svg';
import GameIcon from '../assets/Game.svg';
import { useLoginState } from '../States/UserState';
import { getToken } from '../Repositories/Cookies';
import settingsDark from '../assets/settings-dark.svg';
import settingsIcon from '../assets/Setting.svg';
import downArrows from '../assets/downArrow.svg';
import { PhishingRepository } from '../Repositories/PhishingRepository';
const phishingRepository = new PhishingRepository();
export default function HeaderMenu(props: {
  isEGRC?: boolean;
  token?: string;
  closeVideoForEGRC?: () => void;
}) {
  const language = useLanguageState((state) => state.language);
  const theme = useTheme();
  const loginState = useLoginState();
  const clientName = getToken();
  const [phishingList, setPhishingList] = useState([]);

  useEffect(() => {
    if (loginState.company !== null) {
      if (!props.isEGRC) {
        phishingRepository.getAllCampaigns().then((r) => {
          setPhishingList(r as unknown as any[]);
        });
      }
    }
  }, [loginState.company]);

  const checkIfHasSeePermission = useCallback(
    (tokenName?: string) => {
      if (loginState.token !== null && loginState.token !== undefined) {
        if (tokenName !== undefined) {
          return (
            loginState.token.resource_access[clientName].roles.includes(
              tokenName
            ) &&
            !loginState.token.resource_access[clientName].roles.includes(
              'superadmin'
            )
          );
        } else {
          return !loginState.token.resource_access[clientName].roles.includes(
            'superadmin'
          );
        }
      } else {
        return false;
      }
    },
    [loginState, clientName]
  );

  const isSuperAdmin = useCallback(() => {
    return (
      loginState.token !== null &&
      loginState.token !== undefined &&
      loginState.token.resource_access[clientName].roles.includes('superadmin')
    );
  }, [clientName, loginState.token]);

  const testOnlyPermission = useCallback(() => {
    return (
      // @ts-ignore
      window.env !== undefined &&
      // @ts-ignore
      window.env.KEYCLOAK_REALM === 'awareness_tester' &&
      loginState.token !== null &&
      loginState.token !== undefined &&
      loginState.token.resource_access[clientName].roles.includes(
        'ViewAnalytics'
      )
    );
  }, []);

  const checkPhishingAssigmentPermission = useCallback(() => {
    console.log(
      loginState.token.resource_access[clientName].roles.some((v) => {
        return;
      })
    );

    return (
      loginState.token !== null &&
      loginState.token !== undefined &&
      (loginState.token.resource_access[clientName].roles.includes(
        'realm-admin'
      ) ||
        phishingList?.findIndex(
          (i) => i.responsible_user_id === loginState.token.sub
        ) !== -1 ||
        loginState.token.resource_access[clientName].roles.some((substring) =>
          substring.includes('Phishing')
        ))
    );
  }, [clientName, loginState.token, phishingList]);

  return (
    <div
      className="shadowMenuBottom"
      style={{
        background: theme.palette.mode === 'dark' ? 'transparent' : '#fff',
        backdropFilter: 'blur(10px)',
        height: 70,
        width: '100%'
      }}
    >
      {props.isEGRC && (
        <ul
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            listStyle: 'none'
          }}
        >
          <RouteItem
            isDisabled={true}
            icon={theme.palette.mode === 'dark' ? chartDark : chartIcon}
            to={'/Dashboard'}
            title={I18N('Home', language)}
          />
          <RouteItem
            isDisabled={true}
            icon={theme.palette.mode === 'dark' ? videoDark : videoIcon}
            to={'/documents'}
            title={I18N('documents', language)}
          />
          <RouteItem
            isDisabled={false}
            icon={theme.palette.mode === 'dark' ? videoDark : videoIcon}
            onClick={props.closeVideoForEGRC}
            to={`/egrcContent?egrcAccess=${props.token}`}
            title={I18N('learnContent', language)}
          />
          <RouteItem
            isDisabled={true}
            icon={theme.palette.mode === 'dark' ? lockDark : lockIcon}
            to={'/Passwortpruefung'}
            title={I18N('passwordCheck', language)}
          />
          <RouteItem
            isDisabled={true}
            icon={theme.palette.mode === 'dark' ? messageDark : messageIcon}
            to={'/Emailpruefung'}
            title={I18N('emailCheck', language)}
          />
          <RouteItem
            isDisabled={true}
            icon={theme.palette.mode === 'dark' ? gameDark : GameIcon}
            to={'/phishing'}
            title={I18N('phishing', language)}
          />
          <RouteItem
            isDisabled={true}
            icon={theme.palette.mode === 'dark' ? videoDark : videoIcon}
            to={'/contentAnalytics'}
            title={I18N('contentAnalytics', language)}
          />
        </ul>
      )}
      <ul
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          listStyle: 'none'
        }}
      >
        {isSuperAdmin() && (
          <>
            <RouteItem
              icon={theme.palette.mode === 'dark' ? chartDark : chartIcon}
              to={'/superadmin'}
              title={I18N('dashboard', language)}
            />
            <RouteItem
              icon={theme.palette.mode === 'dark' ? settingsDark : settingsIcon}
              to={'/superadminconfiguration'}
              title={I18N('adminConfig', language)}
            />
            <RouteItem
              icon={theme.palette.mode === 'dark' ? settingsDark : settingsIcon}
              to={'/releasenotes'}
              title={I18N('releaseNotes', language)}
            />
          </>
        )}
        {checkIfHasSeePermission() && (
          <RouteItem
            icon={theme.palette.mode === 'dark' ? chartDark : chartIcon}
            to={'/Dashboard'}
            title={I18N('Home', language)}
          />
        )}

        {testOnlyPermission() && (
          <RouteItem
            icon={theme.palette.mode === 'dark' ? videoDark : videoIcon}
            to={'/documents'}
            title={I18N('documents', language)}
          />
        )}

        {checkIfHasSeePermission('ViewLearningContent') && (
          <RouteItem
            icon={theme.palette.mode === 'dark' ? videoDark : videoIcon}
            to={'/Lernvideos'}
            title={I18N('learnContent', language)}
          />
        )}
        {checkIfHasSeePermission('ViewPasswordTextsTab') && (
          <RouteItem
            icon={theme.palette.mode === 'dark' ? lockDark : lockIcon}
            to={'/Passwortpruefung'}
            title={I18N('passwordCheck', language)}
          />
        )}
        {checkIfHasSeePermission('ViewEmailProofTab') && (
          <RouteItem
            icon={theme.palette.mode === 'dark' ? messageDark : messageIcon}
            to={'/Emailpruefung'}
            title={I18N('emailCheck', language)}
          />
        )}
        {checkPhishingAssigmentPermission() && (
          <RouteItem
            icon={theme.palette.mode === 'dark' ? gameDark : GameIcon}
            to={'/phishing'}
            title={I18N('phishing', language)}
          />
        )}

        {checkIfHasSeePermission('ViewAnalytics') && (
          <RouteItem
            subMenus={[
              { to: '/contentAnalytics', title: 'overView', barAfter: true },
              { to: '/contentAnalytics/contents', title: 'analyticsVideo' },
              {
                to: '/contentAnalytics/departments',
                title: 'analyticsDepartment'
              },
              { to: '/contentAnalytics/users', title: 'analyticsUser' },
              {
                title: 'analyticsCompany',
                to: '/contentAnalytics/companies',
                barAfter: true
              },
              { to: '/contentAnalytics/quiz', title: 'quizTab' },
              { to: '/contentAnalytics/analyticsphishing', title: 'phishing' }
            ]}
            icon={theme.palette.mode === 'dark' ? videoDark : videoIcon}
            to={'/contentAnalytics'}
            title={I18N('contentAnalytics', language)}
          />
        )}
      </ul>
    </div>
  );
}

function isActiveRoute(routeName) {
  return window.location.href.includes(routeName);
}

function RouteItem(props: any) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const language = useLanguageState((state) => state.language);
  return (
    <li
      onClick={props.onClick}
      style={{ position: 'relative', opacity: props.isDisabled ? 0.2 : 1 }}
      onMouseEnter={() => {
        if (props.subMenus) {
          setIsOpen(true);
        }
      }}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Link
        className={`onHoverMenuItem ${isActiveRoute(props.to) && 'active'}`}
        style={{
          padding: '2px 20px',
          borderRadius: 10,
          textDecoration: 'none',
          color: theme.palette.mode === 'light' ? '#2F3640' : '#fff',
          fontSize: 16,
          fontWeight: 350,
          display: 'flex',
          alignItems: 'center',
          marginRight: !props.forSub && 20,
          flexDirection: 'row',
          justifyContent: 'space-between',
          lineHeight: 2
        }}
        to={props.isDisabled ? '/egrcContent' : props.to}
      >
        {props.icon && (
          <img
            style={{ height: 20, width: 20, marginTop: 0, marginRight: 10 }}
            src={props.icon}
          />
        )}
        <p style={{ padding: 0, margin: 0, justifySelf: 'center' }}>
          {props.title}
        </p>
        {props.subMenus && (
          <>
            <img
              style={{ marginLeft: 10, width: 10, height: 10 }}
              src={downArrows}
            />
            <div
              style={{
                boxShadow: ' 0 8px 16px rgba(145, 158, 171, 0.24)',
                display: isOpen ? 'block' : 'none',
                left: 0,
                position: 'absolute',
                top: 0,
                marginTop: 36,
                padding: 5,
                borderRadius: 10,
                background: '#fff',
                height: 'auto',
                width: 'auto'
              }}
            >
              {props.subMenus.map((i) => {
                return (
                  <>
                    <RouteItem
                      forSub={true}
                      to={i.to}
                      title={I18N(i.title, language)}
                    />
                    {i.barAfter && (
                      <div
                        style={{
                          textAlign: 'center',
                          margin: '0 auto',
                          marginBottom: 8,
                          marginTop: 8,
                          justifySelf: 'center',
                          alignSelf: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          width: '40%',
                          height: 1,
                          background: 'rgba(192, 192, 192, 1)'
                        }}
                      />
                    )}
                  </>
                );
              })}
            </div>
          </>
        )}
      </Link>

      <div style={{ clear: 'both' }}></div>
    </li>
  );
}
